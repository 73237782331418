<template>
  <div
    class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end"
  >
    <div class="app-auth-background hidden-on-mobile">&nbsp;</div>
    <div class="app-auth-container">
      <div class="logo">
        <a href="https://www.setxrm.com/" target="_blank">&nbsp;</a>
      </div>
      <p class="auth-description">
        {{
          $t(
            "Login.AuthDescription1",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
        <!-- <br />{{
          $t(
            "Login.AuthDescription2",
            {},
            { locale: this.$store.state.activeLang }
          )
        }} -->
        <!-- <router-link :to="{ path: '/Membership/Register' }">{{
          $t("Buttons.Register", {}, { locale: this.$store.state.activeLang })
        }}</router-link> -->
      </p>
      <form class="needs-validation form-login" novalidate>
        <div class="auth-credentials m-b-xxl">
          <label for="email" class="form-label required">{{
            $t("Login.Email", {}, { locale: this.$store.state.activeLang })
          }}</label>
          <div class="position-relative">
            <input
              type="email"
              data-autofocus="true"
              v-on:keyup.enter="onSubmit()"
              :disabled="formPosting"
              id="email"
              class="form-control m-b-md"
              :spellcheck="$isTextSpellCheck"
              v-model="userData.email"
              required
            />
            <div class="invalid-tooltip">
              {{
                this.$t(
                  "FieldErrors.EmailNotValid",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </div>
          <label for="password" class="form-label required">{{
            $t("Login.Password", {}, { locale: this.$store.state.activeLang })
          }}</label>
          <div class="position-relative">
            <input
              type="password"
              v-on:keyup.enter="onSubmit()"
              :disabled="formPosting"
              id="password"
              class="form-control"
              :spellcheck="$isTextSpellCheck"
              v-model="userData.password"
              required
            />
            <div class="invalid-tooltip">
              {{
                this.$t(
                  "FieldErrors.TextNotValid",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </div>
        </div>
        <div class="auth-submit">
          <button
            v-if="!formPosting"
            type="button"
            @click="onSubmit()"
            :disabled="formPosting"
            class="btn btn-primary"
          >
            {{
              $t("Buttons.Login", {}, { locale: this.$store.state.activeLang })
            }}
          </button>
          <div
            class="spinner-border text-primary"
            role="status"
            v-if="formPosting"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <router-link
            :to="{ path: '/password-reset' }"
            class="auth-forgot-password float-end"
          >
            {{
              $t(
                "Buttons.ForgotYourPassword",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </router-link>
        </div>
      </form>
      <div class="divider"></div>
      <div class="d-flex justify-content-between align-items-center">
        <MembershipLocaleSwitcher />
        <SocialIcons />
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import SocialIcons from "@/components/layout/SocialIcons";
import $ from "jquery";
import MembershipLocaleSwitcher from "@/components/custom/locale-switchers/MembershipLocaleSwitcher.vue";
import {
  removeMoshaNotifications,
  removeModalBackdrops,
  removeModals,
} from "@/core/helpers/dom";
export default {
  name: "Login",
  components: {
    SocialIcons,
    MembershipLocaleSwitcher,
  },
  data() {
    return {
      userData: {
        email: null,
        password: null,
        authRequestFromTypeId: 2,
      },
      formPosting: false,
    };
  },
  methods: {
    onSubmit() {
      var form = $(".form-login"),
        self = this;
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.email) ||
        String.isNullOrWhiteSpace(this.userData.password)
      ) {
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/Authenticate", {
          email: this.$root.encrypt(this.userData.email, true),
          password: this.$root.encrypt(this.userData.password, true),
          authRequestFromTypeId: this.userData.authRequestFromTypeId,
        })
        .then((login_response) => {
          var response = login_response?.data;
          if (response.isOk) {
            this.$store.commit("setUser", response);
            this.$i18n.locale = response.activeLanguage;

            this.$root.applyTheme();
            //get current user and redirecting
            this.$root.getCurrentUser(true);
          } else {
            this.formPosting = false;
            var errorMessage = response.message,
              errorMessageIsNull = String.isNullOrWhiteSpace(errorMessage),
              passwordResetButtonShow =
                response.message ===
                process.env.VUE_APP_MEMBERSHIP_INVALID_EMAIL_OR_PASSWORD_KEY;
            if (errorMessageIsNull) {
              errorMessage = this.$t(
                "BaseModelFields.UnexpectedErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            } else {
              errorMessage = this.$root.getMessageByKey(errorMessage);
            }

            Swal.fire({
              html: `<span class='fw-bold fs-6'>${errorMessage}</span>`,
              icon: "error",
              showDenyButton: passwordResetButtonShow,
              denyButtonText: `<i class='bi bi-lock-fill fs-5'></i> ${this.$t(
                "Buttons.ForgotYourPassword",
                {},
                { locale: this.$store.state.activeLang }
              )}`,
              buttonsStyling: false,
              confirmButtonText: this.$t(
                "Buttons.TryAgain",
                {},
                { locale: this.$store.state.activeLang }
              ),
              customClass: {
                confirmButton: "btn fw-bold btn-success",
                denyButton: "btn fw-bold btn-info me-2",
              },
              reverseButtons: true,
            }).then((result) => {
              if (result.isDenied) {
                self.$router.push({ name: "ForgotPassword" });
              }
            });
          }
        })
        .catch((response) => {
          this.formPosting = false;
          var errorMessage = this.$t(
            "BaseModelFields.UnexpectedErrorHasOccurred",
            {},
            { locale: this.activeLanguage }
          );
          Swal.fire({
            html: `<span class='fw-bold fs-6'>${errorMessage}</span>`,
            icon: "error",
            showDenyButton: false,
            buttonsStyling: false,
            confirmButtonText: this.$t(
              "Buttons.TryAgain",
              {},
              { locale: this.activeLanguage }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
            },
          });
        });
    },
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }

    removeModalBackdrops();
    removeModals();
  },
  unmounted() {
    removeMoshaNotifications();
  },
  created() {
    this.$root.changeDocumentTitle(
      this.$t("Buttons.Login", {}, { locale: this.$store.state.activeLang })
    );
  },
};
</script>
